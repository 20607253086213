.root {
  & h1 {
    color: var(--marketplaceColor);
  }

  & h2 {
    color: var(--marketplaceColor);
  }

  & :global(#mission) p {
    max-width: 395px;
  }
}
